<script lang="ts" setup>
import { mask as vMask } from 'vue-the-mask'
import { ref, onMounted } from 'vue'

import AuthenticationService from '../services/authentication'
import Button from './Button.vue'

interface Props {
  onSubmitPhone: (event: Event) => Promise<void>
  loading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

const inputPhone = ref(null)

onMounted(async () => {
  inputPhone.value.focus()
  AuthenticationService.getAuthInstance().setupRecaptcha('login')
})
</script>

<template>
  <div>
    <h3 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Número de Telefone</h3>

    <p class="mt-2 text-center text-sm text-gray-600">
      Vamos enviar um torpedo SMS com código para confirmar esse número
    </p>

    <form @submit.prevent="props.onSubmitPhone" class="mt-8 space-y-6">
      <div>
        <label for="phone" class="block text-sm font-medium text-slate-500">
          Seu número de telefone celular
        </label>

        <div class="mt-1">
          <input
            :disabled="props.loading"
            aria-describedby="phone-description"
            class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-300 text-purple-500 focus:outline-none focus:z-10 sm:text-sm text-xl font-extrabold"
            id="phone"
            name="phone"
            pattern="(\D*\d{1}){10,11}"
            placeholder="(11) XXXX-XXXX"
            ref="inputPhone"
            required
            title="Telefone inválido"
            autocomplete="off"
            type="tel"
            v-mask="['(##) #####-####']"
          />
        </div>
      </div>

      <Button id="login" class="w-full px-4 py-3 text-md" :is-loading="props.loading"
        >Receber código de confirmação
      </Button>
    </form>
  </div>
</template>
