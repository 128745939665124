<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <div class="flex-1 flex gap-8 items-center justify-center flex-col p4">
          <svg
            width="88"
            height="144"
            viewBox="0 0 88 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 144C16 144 0 144 0 128V16C0 16 0 0 16 0H72C72 0 88 0 88 16V128C88 128 88 144 72 144H16Z"
              fill="#31373D"
            />
            <path d="M8 20H80V124H8V20Z" fill="#E5DDD5" />
            <rect x="8" y="20" width="72" height="22" fill="#075E54" />
            <path
              d="M35.7051 35L33.6426 28.2793H33.5898C33.5977 28.4395 33.6094 28.6816 33.625 29.0059C33.6445 29.3262 33.6621 29.668 33.6777 30.0312C33.6934 30.3945 33.7012 30.7227 33.7012 31.0156V35H32.0781V26.4336H34.5508L36.5781 32.9844H36.6133L38.7637 26.4336H41.2363V35H39.543V30.9453C39.543 30.6758 39.5469 30.3652 39.5547 30.0137C39.5664 29.6621 39.5801 29.3281 39.5957 29.0117C39.6113 28.6914 39.623 28.4512 39.6309 28.291H39.5781L37.3691 35H35.7051Z"
              fill="white"
            />
            <path
              d="M45.9414 28.3145C46.8203 28.3145 47.4941 28.5059 47.9629 28.8887C48.4316 29.2715 48.666 29.8535 48.666 30.6348V35H47.418L47.0723 34.1094H47.0254C46.8379 34.3438 46.6465 34.5352 46.4512 34.6836C46.2559 34.832 46.0312 34.9414 45.7773 35.0117C45.5234 35.082 45.2148 35.1172 44.8516 35.1172C44.4648 35.1172 44.1172 35.043 43.8086 34.8945C43.5039 34.7461 43.2637 34.5195 43.0879 34.2148C42.9121 33.9062 42.8242 33.5156 42.8242 33.043C42.8242 32.3477 43.0684 31.8359 43.5566 31.5078C44.0449 31.1758 44.7773 30.9922 45.7539 30.957L46.8906 30.9219V30.6348C46.8906 30.291 46.8008 30.0391 46.6211 29.8789C46.4414 29.7188 46.1914 29.6387 45.8711 29.6387C45.5547 29.6387 45.2441 29.6836 44.9395 29.7734C44.6348 29.8633 44.3301 29.9766 44.0254 30.1133L43.4336 28.9062C43.7812 28.7227 44.1699 28.5781 44.5996 28.4727C45.0332 28.3672 45.4805 28.3145 45.9414 28.3145ZM46.8906 31.9648L46.1992 31.9883C45.6211 32.0039 45.2188 32.1074 44.9922 32.2988C44.7695 32.4902 44.6582 32.7422 44.6582 33.0547C44.6582 33.3281 44.7383 33.5234 44.8984 33.6406C45.0586 33.7539 45.2676 33.8105 45.5254 33.8105C45.9082 33.8105 46.2305 33.6973 46.4922 33.4707C46.7578 33.2441 46.8906 32.9219 46.8906 32.5039V31.9648Z"
              fill="white"
            />
            <path
              d="M54.168 28.3262C54.2578 28.3262 54.3613 28.332 54.4785 28.3438C54.5996 28.3516 54.6973 28.3633 54.7715 28.3789L54.6367 30.0547C54.5781 30.0352 54.4941 30.0215 54.3848 30.0137C54.2793 30.002 54.1875 29.9961 54.1094 29.9961C53.8789 29.9961 53.6543 30.0254 53.4355 30.084C53.2207 30.1426 53.0273 30.2383 52.8555 30.3711C52.6836 30.5 52.5469 30.6719 52.4453 30.8867C52.3477 31.0977 52.2988 31.3574 52.2988 31.666V35H50.5117V28.4492H51.8652L52.1289 29.5508H52.2168C52.3457 29.3281 52.5059 29.125 52.6973 28.9414C52.8926 28.7539 53.1133 28.6055 53.3594 28.4961C53.6094 28.3828 53.8789 28.3262 54.168 28.3262Z"
              fill="white"
            />
            <path
              d="M58.6445 28.3145C59.5234 28.3145 60.1973 28.5059 60.666 28.8887C61.1348 29.2715 61.3691 29.8535 61.3691 30.6348V35H60.1211L59.7754 34.1094H59.7285C59.541 34.3438 59.3496 34.5352 59.1543 34.6836C58.959 34.832 58.7344 34.9414 58.4805 35.0117C58.2266 35.082 57.918 35.1172 57.5547 35.1172C57.168 35.1172 56.8203 35.043 56.5117 34.8945C56.207 34.7461 55.9668 34.5195 55.791 34.2148C55.6152 33.9062 55.5273 33.5156 55.5273 33.043C55.5273 32.3477 55.7715 31.8359 56.2598 31.5078C56.748 31.1758 57.4805 30.9922 58.457 30.957L59.5938 30.9219V30.6348C59.5938 30.291 59.5039 30.0391 59.3242 29.8789C59.1445 29.7188 58.8945 29.6387 58.5742 29.6387C58.2578 29.6387 57.9473 29.6836 57.6426 29.7734C57.3379 29.8633 57.0332 29.9766 56.7285 30.1133L56.1367 28.9062C56.4844 28.7227 56.873 28.5781 57.3027 28.4727C57.7363 28.3672 58.1836 28.3145 58.6445 28.3145ZM59.5938 31.9648L58.9023 31.9883C58.3242 32.0039 57.9219 32.1074 57.6953 32.2988C57.4727 32.4902 57.3613 32.7422 57.3613 33.0547C57.3613 33.3281 57.4414 33.5234 57.6016 33.6406C57.7617 33.7539 57.9707 33.8105 58.2285 33.8105C58.6113 33.8105 58.9336 33.6973 59.1953 33.4707C59.4609 33.2441 59.5938 32.9219 59.5938 32.5039V31.9648Z"
              fill="white"
            />
            <path
              d="M36 50C36 48.3431 37.3431 47 39 47H76V62C76 63.6569 74.6569 65 73 65H39C37.3431 65 36 63.6569 36 62V50Z"
              fill="#DCF8C6"
            />
            <path
              d="M12 68H49C50.6569 68 52 69.3431 52 71V83C52 84.6569 50.6569 86 49 86H15C13.3431 86 12 84.6569 12 83V68Z"
              fill="white"
            />
            <circle cx="19" cy="31" r="8" fill="#7E22CE" />
            <path
              d="M23.8889 31.8892H21.7712L21.4836 27.4981L20.1765 31.8892H17.9891L16.7952 27.6848L16.5512 31.8892H14.5555L14.939 25.667H18.1372L19.1743 29.1603L20.2375 25.667H23.427L23.8889 31.8892Z"
              fill="white"
            />
            <path
              d="M16.1111 36.3338C15.252 36.3338 14.5555 35.6373 14.5555 34.7782V33.2227H17.6667V34.7782C17.6667 35.6373 16.9702 36.3338 16.1111 36.3338Z"
              fill="#EF4444"
            />
            <path
              d="M19.2222 36.3338C18.3631 36.3338 17.6666 35.6373 17.6666 34.7782V33.2227H20.7777V34.7782C20.7777 35.6373 20.0813 36.3338 19.2222 36.3338Z"
              fill="#FBBF24"
            />
            <path
              d="M22.3333 36.3338H22.3333C21.4742 36.3338 20.7777 35.6373 20.7777 34.7782V33.2227H23.8888V34.7782C23.8888 35.6373 23.1924 36.3338 22.3333 36.3338Z"
              fill="#EF4444"
            />
          </svg>
        </div>
      </div>

      <LoginPhone
        v-if="!steps.phone.done"
        :onSubmitPhone="handlePhoneSubmit"
        :loading="steps.loading"
      />

      <LoginCode
        v-else
        :loading="steps.loading"
        :onSubmitCode="handleCodeSubmit"
        :phone="steps.phone.number"
        :readyForResendOrHelp="steps.readyForResendOrHelp"
        :timeToBeReadyForHelp="steps.timeToBeReadyForHelp"
        :resetFormHandler="resetForm"
        :handleFallback="handleFallback"
        :fallbackLoading="steps.fallbackLoading"
      />

      <div
        v-if="steps.errorMessage"
        class="flex-1 flex gap-8 items-center justify-center flex-col p4"
      >
        <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 w-full" role="alert">
          <p class="font-bold">Ops algo deu errado:</p>
          <p>{{ steps.errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { apm } from '../services/apm'
import { onBeforeMount, onMounted, ref } from 'vue'
import Authentication from '../services/authentication'
import { api } from '../services/api'
import LoginCode from './LoginCode.vue'
import LoginPhone from './LoginPhone.vue'
import { QUERY_STRING_PARAM_TO_REDIRECT } from '../enums/authentication'
import { CHECKOUT_URI } from '../enums/checkout'
import {
  emitConfirmatioCodeInputedEvent,
  emitLoginStartedEvent,
  emitPhoneWasInputedEvent,
  emitLoginSucceedEvent,
} from '../services/dataLayer'
import { getMe } from '../services/users'
import { getReferrer } from '../services/referrer'

const INTERVAL_TIME = 1000
const TIME_TO_BE_READY_TO_HELP = 10000
const searchParams = new URLSearchParams(window.location?.search)

const authService = Authentication.getAuthInstance()
const initialState = {
  phone: {
    prefix: '+55',
    number: searchParams.has('p') ? searchParams.get('p') : '',
    done: searchParams.has('c'),
  },
  code: {
    done: false,
  },
  fallback: {
    done: false,
  },
  readyForResendOrHelp: null,
  timeToBeReadyForHelp: null,
  errorMessage: '',
  loading: false,
  fallbackLoading: false,
  redirectPageURL: getRedirectPageURL(),
}

const steps = ref(getInitialState())

function formatTime(time, intervalTime) {
  return time / intervalTime
}

function decreaseCounterToBeReadyHelp(
  time = TIME_TO_BE_READY_TO_HELP,
  intervalTime = INTERVAL_TIME,
) {
  steps.value.readyForResendOrHelp = false
  steps.value.timeToBeReadyForHelp = formatTime(TIME_TO_BE_READY_TO_HELP, INTERVAL_TIME)
  const interval = setInterval(() => {
    time -= intervalTime
    steps.value.timeToBeReadyForHelp = formatTime(time, intervalTime)
    if (time === 0) {
      clearInterval(interval)
      steps.value.readyForResendOrHelp = true
    }
  }, intervalTime)
}

function getRedirectPageURL() {
  const params = new URLSearchParams(location.search)
  return params.has(QUERY_STRING_PARAM_TO_REDIRECT)
    ? params.get(QUERY_STRING_PARAM_TO_REDIRECT)
    : CHECKOUT_URI
}

function getInitialState() {
  return JSON.parse(JSON.stringify(initialState))
}

function getInputValue(event: Event, name: string) {
  return (event.target as HTMLFormElement).elements[name]?.value.replace(/\D/g, '')
}

async function handlePhoneSubmit(event: Event) {
  steps.value.errorMessage = ''
  steps.value.loading = true
  steps.value.phone.number = getInputValue(event, 'phone')

  emitPhoneWasInputedEvent()

  try {
    await authService.signInWithPhoneNumber(steps.value.phone.prefix + steps.value.phone.number)

    steps.value.phone.done = true
    decreaseCounterToBeReadyHelp()
    searchParams.set('c', '')
    searchParams.set('p', steps.value.phone.number)
    history.pushState(null, null, `/login?${searchParams.toString()}`)
  } catch (error) {
    steps.value.phone.done = false
    steps.value.errorMessage =
      'Nossos servidores estão atualmente fora do ar. Tente novamente mais tarde.'
    throw error
  }

  steps.value.loading = false
}

async function handleCodeSubmit(event: Event) {
  const confirmationCode = getInputValue(event, 'single-factor-code-text-field')
  emitConfirmatioCodeInputedEvent()
  steps.value.errorMessage = ''
  steps.value.loading = true

  try {
    const referer_slug = getReferrer()
    const body: any = {
      phone: steps.value.phone.prefix + steps.value.phone.number,
      referer_slug,
    }

    await authService
      .confirmPhoneNumber(confirmationCode)
      .then(async (userdata) => await userdata.getIdToken())
      .then(async (idToken) => {
        body.id_token = idToken

        const {
          data,
        }: {
          data: {
            access_token: string
            expires: number
          }
        } = await api.post('firebase/connect', { json: body }).json()
        authService.setAccessToken(data.access_token, data.expires)
      })
      .catch(async (error) => {
        body.code = confirmationCode
        const {
          data,
        }: {
          data: {
            access_token: string
            expires: number
          }
        } = await api.post('firebase/connect', { json: body }).json()
        authService.setAccessToken(data.access_token, data.expires)
      })
      .catch(async (error) => {
        await authService.doLogout()
        apm.captureError(error)
      })

    emitLoginSucceedEvent()
    steps.value.code.done = true
    location.replace(steps.value.redirectPageURL)
  } catch (error) {
    steps.value.code.done = false
    steps.value.errorMessage =
      'O código de confirmação é inválido, verifique o seu número de telefone e o código e tente novamente'
    throw error
  }

  steps.value.loading = false
}

async function handleFallback(event: Event) {
  steps.value.errorMessage = null
  steps.value.fallbackLoading = true

  try {
    await api.post('firebase/fallback', {
      json: {
        phone: steps.value.phone.prefix + steps.value.phone.number,
      },
    })
  } catch (error) {
    throw error
  } finally {
    decreaseCounterToBeReadyHelp()
    steps.value.fallbackLoading = false
  }
}

function resetForm() {
  steps.value = getInitialState()
}

onMounted(async () => {
  emitLoginStartedEvent(Authentication.LOGIN_METHOD_NAME)
  if (searchParams.has('c') && searchParams.has('p')) {
    decreaseCounterToBeReadyHelp()
  }
})

onBeforeMount(async () => {
  const isAuthenticated = await getMe()

  if (isAuthenticated) {
    authService.redirectToPreviousUrl()
  }
})
</script>
