<script lang="ts" setup>
import Button from './Button.vue'
import { ref, onBeforeMount } from 'vue'

interface Props {
  onSubmitCode: (event: Event) => Promise<void>
  handleFallback: (event: Event) => Promise<void>
  resetFormHandler: () => void
  phone: string
  loading: boolean
  readyForResendOrHelp: boolean
  timeToBeReadyForHelp: number
  fallbackLoading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  fallbackLoading: false,
})

const helpMessage = ref(undefined)
const target = ref(undefined)

const searchParams = new URLSearchParams(window.location?.search)

const inputCode = ref(searchParams.has('c') ? searchParams.get('c') : null)

function applyPhoneMask(phone: string) {
  return phone.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
}

onBeforeMount(async () => {
  if (!window.navigator.userAgent.toLowerCase().includes('instagram')) {
    helpMessage.value = 'https://wa.me/5511935017868'
    target.value = '_blank'
  }
})
</script>

<template>
  <div>
    <h3 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Código de confirmação</h3>

    <div class="mt-6 text-center text-sm text-gray-600">
      Torpedo SMS enviado para
      <span class="font-extrabold text-gray-900">{{ applyPhoneMask(props.phone) }}</span>
      <br />
      Número errado?
      <a
        href="#"
        @click.prevent="props.resetFormHandler"
        class="font-medium text-indigo-600 hover:text-indigo-500"
      >
        clique aqui para mudar
      </a>
    </div>

    <form
      @submit.prevent="props.onSubmitCode"
      class="flex-1 flex gap-8 items-center justify-center flex-col p-4"
    >
      <div>
        <div class="mt-1">
          <div id="otp" class="flex flex-row justify-center text-center px-2 mt-5">
            <input
              class="shadow-sm focus:ring-purple-500 focus:border-purple-500 rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-300 text-purple-500 focus:outline-none focus:z-10 sm:text-sm text-xl font-extrabold"
              v-model="inputCode"
              type="tel"
              id="single-factor-code-text-field"
              maxlength="6"
              autocomplete="one-time-code"
              autofocus
            />
          </div>
        </div>
      </div>

      <Button id="confirmation" class="w-full px-4 py-3 text-md" :is-loading="props.loading"
        >Confirmar meu número</Button
      >
      <p v-if="!props.readyForResendOrHelp" class="mt-2 text-center text-sm text-gray-600">
        Espere
        <strong>{{ props.timeToBeReadyForHelp }} segundos</strong> para pedir o reenvio do código!
      </p>
      <p v-if="props.readyForResendOrHelp" class="mt-2 text-center text-sm text-gray-600">
        Não recebeu o código?
        <Button
          @click="props.handleFallback"
          :href="helpMessage"
          :variant="'link'"
          :is-loading="props.fallbackLoading"
          target="target"
          class="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Clique aqui para receber o código no WhatsApp
        </Button>
      </p>
    </form>
  </div>
</template>
